import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { defineComponent, ref, reactive, toRaw, computed, toRefs, onMounted, watch, nextTick, createVNode } from 'vue';
import useTablePagination from '@/hooks/pagination';
import { QuestionCircleOutlined, ExclamationCircleOutlined, ProfileOutlined, PartitionOutlined, PaperClipOutlined } from '@ant-design/icons-vue';
import { selectMyPayrollByPagination, selectPayrollDetailById } from '@/api/personnel'; //表头

const columns = [{
  title: '名称',
  dataIndex: 'payrollName'
}, {
  title: '发薪月份',
  dataIndex: 'salaryMonth'
}, {
  title: '实发金额',
  dataIndex: 'netpayAmount'
}, {
  title: '发布时间',
  dataIndex: 'publishedTime'
}, {
  title: '操作',
  dataIndex: 'operation',
  slots: {
    customRender: 'operation'
  }
}];
export default defineComponent({
  components: {
    QuestionCircleOutlined,
    ExclamationCircleOutlined,
    ProfileOutlined,
    PartitionOutlined,
    PaperClipOutlined
  },

  setup() {
    //表格
    const tableData = ref([{}]);
    const loading = ref(false); //分页信息

    const {
      paginationConfig,
      total,
      current,
      pageSize
    } = useTablePagination(); //点击分页

    const onPageChange = pagination => {
      current.value = pagination.current;
      getTableData();
    };

    const getTableData = async () => {
      const param = {
        pageNumber: current.value,
        limit: pageSize.value ? pageSize.value : 10
      };
      loading.value = true;

      try {
        let res = await selectMyPayrollByPagination(param);

        if (res.code === 200) {
          const arr = [];
          tableData.value = [];
          if (res.data.list) res.data.list.forEach(item => {
            arr.push({ ...item,
              key: item.id
            });
          });
          Object.assign(tableData.value, arr);
          total.value = res.data.countSize;
          current.value = res.data.nowPage;
          pageSize.value = res.data.pageSize;
          loading.value = false;
        } else {
          loading.value = false;

          _message.error(res.message);
        }
      } catch {
        loading.value = false;
      }
    }; //查看


    const detailInfo = reactive({
      userName: '',
      netpayAmount: '',
      payableDataList: [],
      deductibleDataList: [],
      remark: null
    });
    const visible = ref(false);

    const toView = async val => {
      try {
        let res = await selectPayrollDetailById(val.id);

        if (res.code === 200) {
          detailInfo.userName = res.data.userName;
          detailInfo.netpayAmount = res.data.netpayAmount;
          detailInfo.remark = res.data.remark;
          detailInfo.payableDataList = [];
          detailInfo.deductibleDataList = [];

          if (res.data.payableDataList) {
            if (res.data.payableDataList.length) {
              let snum = 0;
              res.data.payableDataList.forEach(item => {
                snum += Number(item.value);
                detailInfo.payableDataList.push(item);
              });
              detailInfo.payableDataList.unshift({
                label: '应付项',
                value: snum
              });
            } else {
              detailInfo.payableDataList.push({
                label: '应付项',
                value: '0'
              });
            }
          }

          if (res.data.deductibleDataList) {
            if (res.data.deductibleDataList.length) {
              let snum = 0;
              res.data.deductibleDataList.forEach(item => {
                snum += Number(item.value);
                detailInfo.deductibleDataList.push(item);
              });
              detailInfo.deductibleDataList.unshift({
                label: '应扣项',
                value: snum
              });
            } else {
              detailInfo.deductibleDataList.push({
                label: '应扣项',
                value: '0'
              });
            }
          }

          visible.value = true;
        } else {
          _message.error(res.message);
        }
      } catch (error) {
        console.log(error);
      }
    };

    onMounted(() => {
      getTableData();
    });
    return {
      paginationConfig,
      columns,
      tableData,
      loading,
      onPageChange,
      toView,
      detailInfo,
      visible
    };
  }

});